<template>
  <div class="container addresssss">
    <div class="row">
      <div class="col-lg-3 col-md-4">
        <MySidebar
          active="addresses"
          :user="body.userName"
          :image="body.image"
        />
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="left-profile pt-4 address-card">
          <div class="header-profile">
            <h2>
              {{ $t("addresses") }}
            </h2>

            <button
              class="btn2 btn btn-outline-success"
              @click="$router.push('/addaddress')"
            >
              <b-icon icon="plus" class="plus"></b-icon>
              {{ $t("Add a new address") }}
            </button>
          </div>
          <div class="m-5 text-center" v-if="list.length == 0">
            <img
              src="../../assets/images/noFavoriteillustration(1).svg"
              class="mt-5"
              alt=""
            />
          </div>
          <div class="order-content mt-4" v-for="item of list">
            <div class="date d-flex align-items-center mb-2">
              <img src="../../assets/images/map.svg" alt="" class="ml-5" />
              <div class="content w-100">
                <h6 class="d-inline-block" @click="setIsDefault(item._id)">
                  {{ item.firstName }} {{ item.lastName }}
                </h6>
                <span v-if="item.isDefault">
                  <img src="../../assets/images/s7.svg" alt="" />
                  {{$t("hypothetical")}}
                </span>
                <p class="mb-1">{{ item.phone }}</p>

                <div
                  class="number d-flex justify-content-between align-items-center"
                >
                  <p class="mb-0">
                    {{ item.address }}
                  </p>
                  <img
                    @click="$router.push('/editaddress/' + item._id)"
                    src="../../assets/images/edit.svg"
                    class="img-fluid rounded-start"
                    alt=""
                    style="cursor: pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      body: {
        userName: null,
        email: null,
        phone: null,
        image: null,
      },
      list: [],
    };
  },
  methods: {
    setIsDefault(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("It will be selected as default"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("Yes, Set it!"),
      }).then((result) => {
        if (result.isConfirmed) {
          this.$http.post("address/setIsDefault/" + id, {}).then((res) => {
            this.getData();
            this.$swal({
              title: this.$t("Your file has been Default"),
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          });
        }
      });
    },
    getData() {
      this.$http
        .post("address/getUserAddresses?limit=1000000", {})
        .then((res) => {
          this.list = res.data.docs;
        });
    },
  },
  created() {
    this.$http.post("users/getUserById", {}).then((res) => {
      this.body = res.data;
    });
    this.getData();
  },
};
</script>

<style></style>
